import React from 'react';
import { Helmet } from 'react-helmet';
import { SEO_DOMAIN } from 'utils/constants/seo.js';

const LdProduct = ({ serverData }) => {
  const getImages = () => {
    let images = [];
    serverData?.product?.data?.factoryImages?.forEach((item) => {
      images.push(item?.url);
    });
    return images;
  };

  const getDescription = () => {
    // URL解码
    var encodedText = serverData?.product?.data?.shortDescription;

    // 去除HTML标签
    var textWithoutTags = encodedText.replace(/(<([^>]+)>|\n)/gi, '');

    return textWithoutTags;
  };

  var schemaJSONLDProduct = {
    '@type': 'Product',
    image: getImages(),
    name: `${serverData?.product?.data?.name}`,
    description: getDescription(),
    url: `${SEO_DOMAIN}/product?pid=${serverData?.product?.data?.id}`,
    brand: {
      '@type': 'Brand',
      name: `${serverData?.product?.data?.brand}`,
    },
    offers: {
      '@type': 'Offer',
      price: `${
        serverData?.product?.data?.salePrice !==
        serverData?.product?.data?.price
          ? serverData?.product?.data?.salePrice
          : serverData?.product?.data?.price
      }`,
      priceCurrency: 'TWD',
      availability: 'http://schema.org/InStock',
    },
  };
  var jsonldSchema = {
    '@context': 'https://schema.org/',
    '@graph': schemaJSONLDProduct,
  };
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(jsonldSchema)}
        </script>
      </Helmet>
    </>
  );
};

export default LdProduct;
