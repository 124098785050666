import React from 'react';
import styled, { css } from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'gatsby';

import { md } from 'utils/breakpoints';
import { setSelectItem } from 'utils/_gtag';

import IconCart from 'images/cart-1.svg';

const mixinList = css`
  display: flex;
  flex-direction: column;
  gap: 12px 0;
`;

const mixinListItem = css`
  width: 100%;
  border-radius: 8px;
  height: 105px;
  padding: 6px 8px;
  display: flex;
  gap: 0 4px;
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px 0;

  @media (max-width: ${md}) {
    display: none;
  }

  .skeleton-wrapper {
    ${mixinList}

    .skeleton {
      ${mixinListItem}
    }
  }
`;

const ListTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;
`;

const List = styled.div`
  ${mixinList}
`;

const ListItemFrame = styled.div`
  ${mixinListItem}
  display: contents;
`;

const ListItem = styled(Link)`
  ${mixinListItem}
  display: flex;
  background-color: #ffffff;
  cursor: pointer;
`;

const ListItemImg = styled.img`
  flex: 0 0 100px;
  height: 97px;
`;

const ListItemDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2px;
`;

const ListItemPrice = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;
  text-align: right;

  > span {
    font-size: 12px;
    font-weight: 400;
    margin-right: 2px;
  }
`;

const OthersViewedList = (props) => {
  const { className, product } = props;

  return (
    <Container className={className}>
      <ListTitle>
        <img src={IconCart} />
        其他人也逛過
      </ListTitle>
      {Object.keys(product).length !== 0 ? (
        <List>
          {product.recommendProductList?.map((item) => {
            return (
              <ListItemFrame
                onClick={() => {
                  // GTAG: 點選推薦區商品
                  setSelectItem(
                    {
                      listId: item.productNumber,
                      listName: '其他人也逛過',
                    },
                    item
                  );
                }}
              >
                <ListItem to={`/product?pid=${item.productNumber}`}>
                  <ListItemImg src={item.image} />
                  <ListItemDetail>
                    <div>{item.item_name}</div>
                    <ListItemPrice>
                      <span>$</span>
                      {item.salesPrice}
                    </ListItemPrice>
                  </ListItemDetail>
                </ListItem>
              </ListItemFrame>
            );
          })}
        </List>
      ) : (
        <Skeleton
          containerClassName="skeleton-wrapper"
          className="skeleton"
          count={7}
          inline
        />
      )}
    </Container>
  );
};

export default OthersViewedList;
