import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import store, { actions, getters } from 'redux/store';
import styled, { css } from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';
import { Swiper, SwiperSlide } from 'components/UI/Swiper';
import Auth from 'components/Auth';
import { RadioGroup } from 'components/UI/Radio';
import Counter from 'components/UI/Counter';
import { getDispatchData } from 'utils/_dispatch.js';
import { md } from 'utils/breakpoints';
import { useAPI } from 'utils/api';
import { isAuthenticated } from 'utils/_auth.js';
import {
  setAddToCart,
  setShare,
  setPromotionViewDetail,
  setSoldOutBtn,
} from 'utils/_gtag.js';

import IconLine from 'images/line.svg';
import IconFacebook from 'images/facebook.svg';
import IconMail from 'images/mail.svg';
import IconArrow from 'images/arrow-2.svg';
import { PATH_CART } from 'utils/constants/paths.js';
import Watermark from 'components/UI/Watermark/CardProduct';

import Cookies from 'js-cookie';

const StyledCounter = styled(Counter)`
  .counter-btn {
    width: 28px;
    height: 28px;
  }

  .counter-input {
    width: 56px;
    height: 29px;
  }
`;

const mixinPreview = css`
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: center;
  width: 350px;
  height: 350px;
  margin: 45px auto 32px;

  @media (max-width: ${md}) {
    width: 260px;
    height: 260px;
    margin: 38px auto;
    display: flex;
    justify-content: center;
  }
`;

const mixinPreviewList = css`
  display: flex;
  width: 100%;
  margin-bottom: 16px;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const mixinPreviewItem = css`
  width: 96px;
  height: 96px;
  border: 1px solid #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.isActive &&
    css`
      border: 2px solid #5fd2da;
    `}

  > img {
    width: 80px;
    height: 80px;
  }
`;

const mixinInfoSectionBlock = css`
  display: flex;
  flex-direction: column;
  gap: 8px 0;

  &:nth-child(1) {
    padding-bottom: 8px;

    @media (max-width: ${md}) {
      padding-bottom: 0px;
      margin-bottom: 16px;
    }
  }

  &:nth-child(2) {
    padding: 16px 0px;
    border-top: 1px solid #e3e3e3;

    @media (max-width: ${md}) {
      border-top: none;
      gap: 16px 0;
      padding: 0;
    }
  }

  &:nth-child(3) {
    padding: 16px 0px;
    border-top: 1px solid #e3e3e3;
    gap: 16px 0;

    @media (max-width: ${md}) {
      display: none;
    }
  }

  &:nth-child(4) {
    padding: 40px 0 0;
    border-top: 1px solid #e3e3e3;

    @media (max-width: ${md}) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      height: 75px;
      background-color: #ffffff;
      box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.16);
      padding: 16px;
      z-index: 500;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    ${StyledCounter} {
      display: none;

      @media (max-width: ${md}) {
        display: flex;
      }
    }
  }
`;

const mixinInfoSectionRow = css`
  display: flex;
  align-items: center;
  gap: 0 24px;
  font-size: 14px;
  font-weight: 400;
  color: #3b3516;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 0 24px;

  @media (max-width: ${md}) {
    flex-direction: column;
  }

  .preview-skeleton {
    ${mixinPreview}
  }

  .skeleton-wrapper {
    ${mixinPreviewList}

    .skeleton {
      margin-right: 10px;
      ${mixinPreviewItem}
    }
  }

  .infoSectionRow-skeleton {
    ${mixinInfoSectionRow}
  }
`;

const StyledSwiper = styled(Swiper)``;

const PreviewSection = styled.div`
  width: 375px;
  flex: 0 0 auto;

  @media (max-width: ${md}) {
    width: 100%;
  }
`;

const PreviewWrapper = styled.div`
  position: relative;
`;

const Preview = styled.div`
  ${mixinPreview}
`;

const PreviewList = styled.div`
  ${mixinPreviewList}
`;

const PreviewItem = styled.div`
  ${mixinPreviewItem}
`;

const ShareBtnGroup = styled.div`
  display: flex;
  gap: 0 8px;

  @media (max-width: ${md}) {
    display: none;
  }

  > img {
    cursor: pointer;
  }
`;

const InfoSection = styled.div`
  width: 649px;
  flex: 1;

  @media (max-width: ${md}) {
    width: 100%;
  }
  .productName-skeleton {
    ${mixinInfoSectionBlock};
  }
`;

const InfoSectionRowValue = styled.div``;

const InfoSectionBlock = styled.div`
  ${mixinInfoSectionBlock}
`;

const ProductName = styled.div`
  font-size: 32px;
  font-weight: 700;
  color: #3b3516;

  @media (max-width: ${md}) {
    font-size: 24px;
  }
`;

const ProductDescriptionList = styled.div`
  display: flex;
  gap: 0 16px;
  flex-wrap: wrap;

  @media (max-width: ${md}) {
    width: 100%;
  }
`;

const ProductDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #868686;
`;

const InfoSectionRow = styled.div`
  ${mixinInfoSectionRow}
`;

const InfoSectionRowLabel = styled.div`
  flex: 0 0 56px;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const ProductOriginalPrice = styled.div`
  font-size: 32px;
  font-weight: 700;
  color: #3b3516;

  @media (max-width: ${md}) {
    font-size: 24px;
  }
`;

const DiscountLabel = styled.div`
  width: 343px;
  padding: 10px;
  background-color: #fcf8e6;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
`;

const CheckDiscountBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 0 8px;
  cursor: pointer;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const ShippingMethodLabel = styled.div`
  padding: 4px 16px;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 90px;
`;

const PurchaseBtnWrapper = styled.div`
  display: flex;
  border-radius: 99px;
  border: 1px solid #5fd2da;
  width: 436px;
  height: 56px;
  overflow: hidden;
  cursor: pointer;

  @media (max-width: ${md}) {
    width: 154px;
    height: 100%;
  }
`;

const PurchaseBtn = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #5fd2da;
  font-size: 20px;
  font-weight: 500;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const AddToCartBtn = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5fd2da;
  color: #fff;
  font-size: 20px;
  font-weight: 700;

  @media (max-width: ${md}) {
    font-size: 18px;
  }
`;

const outOfStockList = [
  { label: '缺貨刪除', value: '1' },
  { label: '換貨(更換同系列其他商品或他牌相同口味)', value: '2' },
  { label: '整單取消', value: '3' },
];

const ProductDisplay = (props) => {
  const {
    className,
    product,
    productId,
    boardInfo = undefined,
    mobileOutOfStock,
  } = props;
  const api = useAPI();

  const cartList = useSelector((state) => getters.getCartList(state));

  const [outOfStock, setOutOfStock] = useState('1');
  const [count, setCount] = useState(1);
  const [factoryImages, setFactoryImages] = useState();
  const [preview, setPreview] = useState({});
  const [doLogin, setDoLogin] = useState(false);

  const productInCart = cartList.find((item) => item.productId === productId);
  const qcMaxOrderQuantity = Number(product.c_qcMaxOrderQuantity);
  const productMaxCount = productInCart
    ? qcMaxOrderQuantity - productInCart.qty
    : qcMaxOrderQuantity;

  const addToCart = (isRightNow) => {
    const dispatchData = getDispatchData();
    api
      .addCart({
        productId: productId,
        quantity: isRightNow ? 1 : count,
        itemOutofstockAction: isMobile
          ? parseInt(mobileOutOfStock)
          : parseInt(outOfStock),
        currentStoreId: dispatchData?.storeId ?? '',
      })
      .then((res) => {
        if (!res) return;
        if (_.get(res, 'isNotEnoughStock', false)) {
          // GTAG: 3. 商品缺貨
          const reason = _.get(res, 'reason', '');
          alert(reason, () => {
            setSoldOutBtn();
          });
          return;
        }
        if (isRightNow) {
          navigate(PATH_CART);
          return;
        }
        store.dispatch(actions.setCartItemCount(res.totalqtyCount));

        // 從cookie中取得attributionToken
        const attributionToken = Cookies.get('attributionToken');
        // GTAG: 點選加入購物車
        setAddToCart(res, productId, product, boardInfo, attributionToken);
        popup('加入成功');
        // ALVIN 2023/05/23 update redux cart & cart price
        //store.dispatch(actions.setCartList(res.productItems));
        //store.dispatch(actions.setCartPrice(res));
      })
      .catch((err) => alert('err', err));
  };

  const openSocialMedia = (key) => {
    const socialMediaUrl = {
      line: `https://social-plugins.line.me/lineit/share?url=${window.location.href}`,
      fb: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
      mail: `mailto:?subject=${product.name}&body=${window.location.href}`,
    };
    // GTAG: 4. 分享內容
    setShare(key);
    window.open(socialMediaUrl[key], '_blank', 'width=500,height=500');
  };

  const handleChangeCount = (num) => {
    setCount(num);
    if (num >= productMaxCount) {
      alert('已達商品購買上限');
      return;
    }
  };

  useEffect(() => {
    setFactoryImages(undefined);
    setPreview({});
    setCount(1);
    setOutOfStock('1');
    if (product.factoryImages) {
      setFactoryImages(product.factoryImages);
      setPreview({ url: product.factoryImages[0].url, index: 0 });
    }
  }, [product]);

  return (
    <Container className={className}>
      <PreviewSection>
        {factoryImages !== undefined ? (
          <>
            <PreviewWrapper>
              <Preview imageUrl={preview.url}></Preview>
              {preview.index === 0 && <Watermark product={product} />}
            </PreviewWrapper>
            <PreviewList>
              {factoryImages.length >= 4 ? (
                <StyledSwiper slidesPerView={3.5} spaceBetween={10}>
                  {factoryImages.map((item, idx) => {
                    return (
                      <SwiperSlide key={idx}>
                        <PreviewItem
                          isActive={preview.url === item.url}
                          onClick={() =>
                            setPreview({ url: item.url, index: idx })
                          }
                        >
                          <img src={item.url} />
                        </PreviewItem>
                      </SwiperSlide>
                    );
                  })}
                </StyledSwiper>
              ) : (
                factoryImages.map((item, idx) => {
                  return (
                    <PreviewItem
                      key={idx}
                      style={{ marginRight: 10 }}
                      isActive={preview.url === item.url}
                      onClick={() => setPreview({ url: item.url, index: idx })}
                    >
                      <img src={item.url} />
                    </PreviewItem>
                  );
                })
              )}
            </PreviewList>
          </>
        ) : (
          <>
            <Skeleton className="preview-skeleton" />
            <Skeleton
              containerClassName="skeleton-wrapper"
              className="skeleton"
              count={3}
              inline
            />
          </>
        )}

        <ShareBtnGroup>
          <img src={IconLine} onClick={() => openSocialMedia('line')} />
          <img src={IconFacebook} onClick={() => openSocialMedia('fb')} />
          <img src={IconMail} onClick={() => openSocialMedia('mail')} />
        </ShareBtnGroup>
      </PreviewSection>
      <InfoSection>
        {Object.keys(product).length > 0 ? (
          <>
            <InfoSectionBlock>
              <ProductName>{product.name}</ProductName>
              <ProductDescriptionList>
                <ProductDescription>
                  ◎銷售入數:{product.c_packageQty}入
                </ProductDescription>
                <ProductDescription>
                  ◎規格:{product.c_Specification}
                </ProductDescription>
                <ProductDescription>◎品牌:{product.brand}</ProductDescription>
              </ProductDescriptionList>
            </InfoSectionBlock>
          </>
        ) : (
          <Skeleton
            containerClassName="productName-skeleton"
            count={2}
            height={30}
            inline
          />
        )}
        <InfoSectionBlock>
          {Object.keys(product).length > 0 ? (
            <>
              <InfoSectionRow>
                <InfoSectionRowLabel>原價</InfoSectionRowLabel>
                {product && (
                  <ProductOriginalPrice>
                    {product.salePrice !== product.price
                      ? `$${product.salePrice || ''}`
                      : `$${product.price || ''}`}
                  </ProductOriginalPrice>
                )}
              </InfoSectionRow>
              {product?.productPromotions?.length > 0 &&
                product?.productPromotions?.find(
                  (promotion) => promotion.promotionName !== ''
                ) != null && (
                  <InfoSectionRow>
                    <InfoSectionRowLabel>促銷活動</InfoSectionRowLabel>
                    <DiscountLabel>
                      {
                        product.productPromotions.find(
                          (promotion) => promotion.promotionName !== ''
                        )?.promotionName
                      }
                    </DiscountLabel>
                  </InfoSectionRow>
                )}
            </>
          ) : (
            <Skeleton
              containerClassName="infoSectionRow-skeleton"
              height={45}
            />
          )}
        </InfoSectionBlock>
        <InfoSectionBlock>
          <InfoSectionRow>
            <InfoSectionRowLabel>數量</InfoSectionRowLabel>
            <StyledCounter
              max={productMaxCount}
              value={count}
              onChange={handleChangeCount}
            />
          </InfoSectionRow>
          <InfoSectionRow>
            <InfoSectionRowLabel>出貨方式</InfoSectionRowLabel>
            <ShippingMethodLabel>一般訂單</ShippingMethodLabel>
          </InfoSectionRow>
          <InfoSectionRow>
            <InfoSectionRowLabel>此商品 缺貨時</InfoSectionRowLabel>
            <InfoSectionRowValue>
              <RadioGroup
                name="out-of-stock"
                align="vertical"
                list={outOfStockList}
                defaultValue={outOfStock}
                value={outOfStock}
                onChange={(e) => setOutOfStock(e.target.value)}
              />
            </InfoSectionRowValue>
          </InfoSectionRow>
        </InfoSectionBlock>
        <InfoSectionBlock>
          <StyledCounter
            max={productMaxCount}
            value={count}
            onChange={handleChangeCount}
          />
          <PurchaseBtnWrapper>
            <PurchaseBtn
              onClick={() => {
                isAuthenticated() ? addToCart(true) : setDoLogin(true);
              }}
            >
              立即購買
            </PurchaseBtn>
            <AddToCartBtn
              onClick={() => {
                isAuthenticated() ? addToCart(false) : setDoLogin(true);
              }}
            >
              加入購物車
            </AddToCartBtn>
          </PurchaseBtnWrapper>
        </InfoSectionBlock>
      </InfoSection>
      {doLogin && <Auth />}
    </Container>
  );
};

export default ProductDisplay;
