import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { md } from 'utils/breakpoints';
import { SEO_DOMAIN } from 'utils/constants/seo.js';

const Container = styled.div`
  width: 1080px;

  @media (max-width: ${md}) {
    width: 100%;
  }
`;

const SeoProduct = ({ serverData }) => {
  const getDescription = () => {
    // URL解码
    var encodedText = serverData?.product?.data?.shortDescription;

    // 去除HTML标签
    var textWithoutTags = encodedText.replace(/(<([^>]+)>|\n)/gi, '');

    return textWithoutTags;
  };

  return (
    <>
      <h1>{serverData?.product?.data?.name}</h1>
      <div typeof="schema:Product">
        <div
          property="schema:name"
          content={serverData?.product?.data?.name}
        ></div>
        {serverData?.product?.data?.factoryImages?.map((item) => {
          return <div rel="schema:image" resource={item?.url}></div>;
        })}
        <div property="schema:description" content={getDescription()}></div>
        <div
          rel="schema:url"
          resource={`${SEO_DOMAIN}/product?pid=${serverData?.product?.data?.id}`}
        ></div>
        <div rel="schema:brand">
          <div typeof="schema:Brand">
            <div
              property="schema:name"
              content={serverData?.product?.data?.brand}
            ></div>
          </div>
        </div>
        <div rel="schema:offers">
          <div typeof="schema:Offer">
            <div
              property="schema:price"
              content={
                serverData?.product?.data?.salePrice !==
                serverData?.product?.data?.price
                  ? serverData?.product?.data?.salePrice
                  : serverData?.product?.data?.price
              }
            ></div>
            <div
              property="schema:availability"
              content="https://schema.org/InStock"
            ></div>
            <div property="schema:priceCurrency" content="TWD"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SeoProduct;
