import React from 'react';
import { Helmet } from 'react-helmet';

const MetaHome = ({ serverData }) => {
  return (
    <>
      <Helmet>
        <meta property="og:type" content="website" defer={false}></meta>
        <title>{`${serverData?.product?.data?.name} | 家速配，最快1小時送達`}</title>
        <h1>{`${serverData?.product?.data?.name}`}</h1>
        <meta
          property="description"
          content={`最實惠的${serverData?.product?.data?.name}價格就在家速配！購買最熱銷的${serverData?.product?.data?.name}，新客輸入折扣碼「NEW」享99元免運！方便買快速配，無論是煮飯、拜拜、工作、家庭等各種生活所需，家速配提供最快速到貨服務。下載家速配APP更方便！`}
        ></meta>
        <meta
          property="og:title"
          content={`${serverData?.product?.data?.name} | 家速配，最快1小時送達`}
        />
        <meta
          property="og:description"
          content={`最實惠的${serverData?.product?.data?.name}價格就在家速配！購買最熱銷的${serverData?.product?.data?.name}，新客輸入折扣碼「NEW」享99元免運！方便買快速配，無論是煮飯、拜拜、工作、家庭等各種生活所需，家速配提供最快速到貨服務。下載家速配APP更方便！`}
        ></meta>
        <meta property="og:url" content={`${serverData?.currentUrl}`} />
        {/* logo圖檔放在static裏面 部署的時候會搬到public */}
        <meta
          property="og:image"
          content={`${serverData?.product?.data?.factoryImages[0]?.url}`}
        />
      </Helmet>

      {/* <Helmet>
        <meta property="og:type" content="website" defer={false}></meta>
        <meta
          property="og:title"
          content={`Buy ${serverData?.product?.data?.name} for TWD ${
            serverData?.product?.data?.salePrice !==
            serverData?.product?.data?.price
              ? serverData?.product?.data?.salePrice
              : serverData?.product?.data?.price
          } | 家樂福線上購物`}
        />
        <meta
          property="og:url"
          content={typeof window !== 'undefined' ? window.location.href : ''}
        ></meta>
        <meta
          property="og:description"
          content={`${serverData?.product?.data?.name}，更多優惠商品都在家樂福線上購物！`}
        ></meta>
      </Helmet> */}
    </>
  );
};

export default MetaHome;
