import React from 'react';
import styled from 'styled-components';

import IconCounterAdd from 'images/counter-add.svg';
import IconCounterSub from 'images/counter-sub.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const CounterBtn = styled.div`
  width: 24px;
  height: 24px;
  background-color: #5fd2da;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  cursor: pointer;
`;

const SubtractBtn = styled(CounterBtn)`
  background-image: url(${IconCounterSub});
`;

const AddBtn = styled(CounterBtn)`
  background-image: url(${IconCounterAdd});
`;

const CounterInput = styled.input`
  width: 43px;
  height: 27px;
  border: 1px solid #e3e3e3;
  margin: 0 8px;
  color: #333333;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  outline: none;
`;

const Counter = (props) => {
  const { className, max, onChange, value = 1 } = props;
  const maxNum = Number(max);
  const valueNum = Number(value);

  const handleChangeInput = (e) => {
    const value = e.target.value;
    if (value === '') {
      onChange(0);
    }
    if (/^\d+$/.test(value)) {
      const valueNum = Number(value);
      onChange(valueNum > maxNum ? maxNum : valueNum);
    }
  };

  return (
    <Container className={className}>
      <SubtractBtn
        className="counter-btn"
        onClick={() => onChange(valueNum <= 0 ? 0 : valueNum - 1)}
      />
      <CounterInput
        className="counter-input"
        value={value}
        onChange={handleChangeInput}
      />
      <AddBtn
        className="counter-btn"
        onClick={() => onChange(valueNum >= maxNum ? maxNum : valueNum + 1)}
      />
    </Container>
  );
};

export default Counter;
