import React, { useEffect, useState, useRef } from 'react';
import { Link, navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import CcAuth from 'components/CcAuth';
import HeaderWrapper from 'components/UI/Header/HeaderWrapper';
import Header from 'components/UI/Header';
import Breadcrumb from 'components/UI/Breadcrumb';
import Footer from 'components/UI/Footer';
import OthersViewedList from 'components/Product/OthersViewedList';
import RecommendList from 'components/Product/RecommendList';
import ProductInfo from 'components/Product/ProductInfo';
import ProductDisplay from 'components/Product/ProductDisplay';
import { RadioGroup } from 'components/UI/Radio';
import SeoProduct from 'components/Seo/SeoProduct';
import LdProduct from 'components/Seo/JsonLd/LdProduct';
import MetaProduct from 'components/Seo/Meta/MetaProduct';
import { SEO_API_PRODUCT_DETAIL } from 'utils/constants/seo.js';
import { md } from 'utils/breakpoints';
import { useAPI } from 'utils/api';
import { isAuthenticated } from 'utils/_auth.js';
import { getDispatchData } from 'utils/_dispatch.js';
import { setViewItem } from 'utils/_gtag.js';
import { PATH_HOME } from 'utils/constants/paths.js';
import isbot from 'isbot';

import Cookies from 'js-cookie';

const Container = styled.div`
  width: 1080px;
  margin: ${(props) => `${props.headerHeight}px`} auto 0;

  @media (max-width: ${md}) {
    width: 100%;
    background-color: #fff;
  }
`;

const StyledHeader = styled(Header)`
  .switch-delivery-wrapper,
  .address-wrapper,
  .input-search-wrapper {
    @media (max-width: ${md}) {
      display: none;
    }
  }
`;

const StyledOthersViewedList = styled(OthersViewedList)`
  flex: 0 0 232px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px 0;

  @media (max-width: ${md}) {
    gap: 0;
    padding: 0 16px 16px;
  }
`;

const MainSection = styled.div`
  padding: 16px 16px 48px;
  display: flex;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;

  @media (max-width: ${md}) {
    padding: 0;
  }
`;

const SubSection = styled.div`
  display: flex;
  gap: 0 16px;
`;

const SubSectionBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px 0;
  width: 500px;
  flex: 1;
`;

const OutOfStockWrapper = styled.div`
  display: none;

  @media (max-width: ${md}) {
    display: block;
  }
`;

const OutOfStockWrapperTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #3b3516;
  margin-bottom: 16px;
`;

const outOfStockList = [
  { label: '缺貨刪除', value: '1' },
  { label: '換貨(更換同系列其他商品或他牌相同口味)', value: '2' },
  { label: '整單取消', value: '3' },
];

const isGoogleBot = (res) => {
  return /Googlebot/.test(res);
};

const Product = ({ location, serverData }) => {
  console.log('serverData', serverData);
  const api = useAPI();
  const dispatchData = getDispatchData();
  const storeId = dispatchData?.storeId ?? '';

  const [headerHeight, setHeaderHeight] = useState(0);
  const [product, setProduct] = useState({});
  const [outOfStock, setOutOfStock] = useState('1');
  const [ccAuthIsReady, setCcAuthIsReady] = useState(false);
  const [domLoaded, setDomLoaded] = useState(false);

  const productId = useRef('');
  const searchParams = new URLSearchParams(location.search);
  productId.current = searchParams.get('pid') ? searchParams.get('pid') : '';

  const productDetail = () => {
    api
      .getProduct(productId.current, storeId)
      .then((res) => {
        // if (!res) window.history.goBack();先註解等待PM與c4談論如何處理
        if (!res) {
          alert('無效的商品資訊', () => {
            navigate(PATH_HOME);
          });
          return;
        }

        setProduct(res);
        setTimeout(() => {
          // 從cookie取得attributionToken
          const attributionToken = Cookies.get('attributionToken');
          setViewItem(res, attributionToken);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const productDetailNoToken = () => {
    api
      .getProductDetailNoToken(productId.current, storeId)
      .then((res) => {
        // if (!res) window.history.goBack();先註解等待PM與c4談論如何處理
        if (!res) {
          alert('無效的商品資訊', () => {
            navigate(PATH_HOME);
          });
          return;
        }
        setProduct(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!ccAuthIsReady) return;

    if (productId.current === '') {
      navigate(PATH_HOME);
      return;
    }

    setProduct({});
    //判斷有沒有登入
    if (isAuthenticated() && ccAuthIsReady && storeId !== '') {
      productDetail();
    } else if (storeId !== '') {
      productDetailNoToken();
    }
  }, [productId.current, ccAuthIsReady]);

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  return (
    <>
      <CcAuth onFinished={() => setCcAuthIsReady(true)} />
      {serverData?.status === 200 && (
        <>
          <LdProduct serverData={serverData}></LdProduct>
          <MetaProduct serverData={serverData}></MetaProduct>
        </>
      )}

      {isbot(serverData?.useragent) ? (
        <SeoProduct serverData={serverData}></SeoProduct>
      ) : (
        domLoaded &&
        ccAuthIsReady && (
          <div>
            <Container headerHeight={headerHeight}>
              <HeaderWrapper getHeight={(height) => setHeaderHeight(height)}>
                <StyledHeader title="商品詳情" />
              </HeaderWrapper>
              <Breadcrumb
                items={[
                  { title: <Link to="/">首頁</Link> },
                  { title: <Link>{product.main_category}</Link> },
                  {
                    title: (
                      <Link to={`/category?cid=${product.sub_category?.id}`}>
                        {product.sub_category?.name}{' '}
                      </Link>
                    ),
                  },
                ]}
              />
              <ContentWrapper>
                <MainSection>
                  <ProductDisplay
                    product={product}
                    productId={productId.current}
                    mobileOutOfStock={outOfStock}
                  />
                </MainSection>
                <SubSection>
                  <StyledOthersViewedList product={product} />
                  <SubSectionBlock>
                    <ProductInfo product={product} />
                    <RecommendList />
                    <OutOfStockWrapper>
                      <OutOfStockWrapperTitle>
                        此商品缺貨時...
                      </OutOfStockWrapperTitle>
                      <RadioGroup
                        name="out-of-stock"
                        align="vertical"
                        list={outOfStockList}
                        defaultValue={outOfStock}
                        value={outOfStock}
                        onChange={(e) => setOutOfStock(e.target.value)}
                      />
                    </OutOfStockWrapper>
                  </SubSectionBlock>
                </SubSection>
              </ContentWrapper>
              <Footer />
            </Container>
          </div>
        )
      )}
    </>
  );
};

export default Product;

export async function getServerData(context) {
  try {
    const headers = context.headers;
    const productId = context.query.pid;
    if (!productId) {
      throw new Error(`Response failed`);
    }
    const currentUrl = 'https://' + headers.get('host') + '?pid=' + productId;
    const host = headers.get('host');

    const [res1] = await Promise.all([
      fetch(`${SEO_API_PRODUCT_DETAIL}?productId=${productId}`),
    ]);

    if (!res1.ok) {
      throw new Error(`Response failed`);
    }

    const product = await res1.json();

    if (product.code !== '200') {
      throw new Error(`Response failed`);
    }

    const mergedData = {
      status: 200,
      product,
      host: host,
      currentUrl: currentUrl,
      useragent: headers.get('user-agent'),
    };

    console.log('mergedData', mergedData);

    return {
      props: await mergedData,
    };
  } catch (error) {
    return {
      props: {
        status: 500,
      },
    };
  }
}
