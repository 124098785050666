import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import IconArrowDown from 'images/arrow-down.svg';

import Skeleton from 'react-loading-skeleton';

import parse from 'html-react-parser';
import { md } from 'utils/breakpoints';

const Container = styled.div`
  padding: 20px 24px 24px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;

  @media (max-width: ${md}) {
    padding: 0;
  }
`;

const Tabs = styled.div`
  display: block;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const TabList = styled.div`
  display: flex;
  gap: 0 32px;
  margin-bottom: 8px;
  border-bottom: 1px solid #e3e3e3;
`;

const Tab = styled.div`
  padding: 12px 4px 6px;
  font-size: 20px;
  font-weight: 500;
  color: #868686;
  cursor: pointer;

  ${(props) =>
    props.isActive &&
    css`
      border-bottom: 3px solid #5fd2da;
      font-weight: 700;
      color: #3b3516;
    `}
`;

const TabContent = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;

  img {
    width: 100% !important;
    height: auto !important;
  }
`;

const InfoTable = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
`;

const InfoTableRow = styled.div`
  display: flex;
`;

const InfoTableRowLabel = styled.div`
  flex: 0 0 192px;
  background-color: #eeeeee;
  border: 1px solid #e3e3e3;
  padding: 10px 16px;
  display: flex;
  align-items: center;

  @media (max-width: ${md}) {
    flex: 0 0 50px;
    padding: 8px;
  }
`;

const InfoTableRowValue = styled.div`
  flex: 1;
  border: 1px solid #e3e3e3;
  padding: 10px 16px;
`;

const Collapse = styled.div`
  display: none;

  @media (max-width: ${md}) {
    display: block;
  }
`;

const CollapsePanelContent = styled.div`
  padding: 8px 0 0;
  display: none;

  img {
    width: 100% !important;
    height: auto !important;
  }
`;

const CollapsePanel = styled.div`
  position: relative;
  padding: 16px 0;
  border-bottom: 1px solid #e3e3e3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 700;
  color: #3b3516;

  &::before {
    content: '${(props) => props.header}';
  }

  &::after {
    content: '';
    background-image: url(${IconArrowDown});
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    width: 12px;
    height: 7px;
  }
`;

const CollapseContent = styled.div`
  ${CollapsePanelContent} {
    ${(props) =>
      props.isActive &&
      css`
        display: block;
      `}
  }

  ${CollapsePanel} {
    ${(props) =>
      props.isActive &&
      css`
        &::after {
          transform: rotate(180deg);
        }
      `}
  }
`;

const list = ['商品資訊', '規格介紹', '配送及售後服務說明'];

const ProductInfo = (props) => {
  const { className, product } = props;

  const [productInfoIdx, setProductInfoIdx] = useState(0);

  return (
    <Container className={className}>
      <Tabs>
        <TabList>
          {list.map((tab, idx) => {
            return (
              <Tab
                key={idx}
                isActive={idx === productInfoIdx}
                onClick={() => setProductInfoIdx(idx)}
              >
                {tab}
              </Tab>
            );
          })}
        </TabList>
        <TabContent>
          {productInfoIdx === 0 && (
            <>
              {Object.keys(product).length !== 0 ? (
                <div>
                  {product.c_productSpecialty !== '' && (
                    <div>
                      {product.c_productSpecialty}
                      <br></br>
                      <br></br>
                    </div>
                  )}
                  {product.shortDescription &&
                    parse(product.shortDescription + '')}
                  <br></br>
                  {product.longDescription &&
                    parse(product.longDescription + '')}
                  <br></br>
                  {product.c_ingredients && parse(product.c_ingredients + '')}
                  <br></br>
                  {product.c_nutritionalValues &&
                    parse(product.c_nutritionalValues + '')}
                </div>
              ) : (
                <Skeleton height={400} borderRadius={5} />
              )}
            </>
          )}
          {productInfoIdx === 1 && (
            <InfoTable>
              {product?.specDescription?.map((data, idx) => {
                return (
                  <InfoTableRow key={idx}>
                    <InfoTableRowLabel>{data.label}</InfoTableRowLabel>
                    <InfoTableRowValue>
                      {parse(data.value[0])}
                    </InfoTableRowValue>
                  </InfoTableRow>
                );
              })}
            </InfoTable>
          )}
          {productInfoIdx === 2 && (
            <div> {parse(product.serviceDescription + '')}</div>
          )}
        </TabContent>
      </Tabs>
      <Collapse>
        {list.map((item, idx) => {
          const isActive = idx === productInfoIdx;
          return (
            <CollapseContent key={idx} isActive={isActive}>
              <CollapsePanel
                header={item}
                onClick={() => {
                  setProductInfoIdx(isActive ? null : idx);
                }}
              />
              <CollapsePanelContent show={isActive}>
                {productInfoIdx === 0 && (
                  <>
                    {Object.keys(product).length !== 0 ? (
                      <div>
                        {product.c_productSpecialty !== '' ? (
                          <div>
                            {product.c_productSpecialty}
                            <br></br>
                            <br></br>
                          </div>
                        ) : undefined}
                        {parse(product.shortDescription + '')}
                        <br></br>
                        {parse(product.longDescription + '')}
                        <br></br>
                        {parse(product.c_ingredients + '')}
                        <br></br>
                        {parse(product.c_nutritionalValues + '')}
                      </div>
                    ) : (
                      <Skeleton height={300} borderRadius={5} />
                    )}
                  </>
                )}
                {productInfoIdx === 1 && (
                  <InfoTable>
                    {product?.specDescription?.map((data, idx) => {
                      return (
                        <InfoTableRow key={idx}>
                          <InfoTableRowLabel>{data.label}</InfoTableRowLabel>
                          <InfoTableRowValue>
                            {parse(data.value[0])}
                          </InfoTableRowValue>
                        </InfoTableRow>
                      );
                    })}
                  </InfoTable>
                )}
                {productInfoIdx === 2 && (
                  <div>{parse(product.serviceDescription + '')}</div>
                )}
              </CollapsePanelContent>
            </CollapseContent>
          );
        })}
      </Collapse>
    </Container>
  );
};

export default ProductInfo;
