import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Navigation } from 'swiper';
import { useSelector } from 'react-redux';
import store, { actions, getters } from 'redux/store';
import { Swiper, SwiperSlide } from 'components/UI/Swiper';
import CardProduct from 'components/UI/Card/CardProduct';

import { md } from 'utils/breakpoints';
import { setSelectItem } from 'utils/_gtag.js';

import IconFire from 'images/fire.svg';

const Container = styled.div`
  @media (max-width: ${md}) {
    display: none;
  }
`;

const ListTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`;

const RecommendList = (props) => {
  const { className } = props;

  const [purchasedList, setPurchasedList] = useState();

  useEffect(() => {
    const frequentlyPurchasedList = localStorage.getItem(
      'frequentlyPurchasedList'
    );
    if (frequentlyPurchasedList) {
      setPurchasedList(JSON.parse(frequentlyPurchasedList));
    }
  }, []);

  return (
    <>
      {purchasedList?.length > 0 && (
        <Container className={className}>
          <ListTitle>
            <img src={IconFire} />
            專屬推薦
          </ListTitle>
          <Swiper
            modules={[Navigation]}
            navigation
            slidesPerView={4}
            spaceBetween={2}
            breakpoints={{
              768: {
                slidesPerView: 4,
                spaceBetween: 2,
              },
            }}
          >
            {purchasedList.map((item, idx) => {
              const itemData = {
                id: item.productNumber,
                img: item.image,
                price: item.salesPrice,
                originPrice: item.price,
                name: item.item_name,
              };

              return (
                <SwiperSlide key={idx}>
                  <CardProduct
                    goToPage={`/product?pid=${itemData.id}`}
                    item={itemData}
                    boardInfo={{ name: '專屬推薦' }}
                    onGo2Detail={(id) => {
                      // GTAG: 點選推薦區商品
                      setSelectItem(
                        {
                          listId: id,
                          listName: '專屬推薦',
                        },
                        item
                      );
                    }}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Container>
      )}
    </>
  );
};

export default RecommendList;
